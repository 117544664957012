<template>
  <!-- Main container -->
  <b-container fluid>
     <b-col lg="12">
        <iq-card class="iq-card-block iq-card-stretch iq-card-height" >
           <b-card-body class="box iq-box-relative rounded m-0">
              <div class="d-flex align-items-center">
                 <div class="mr-3">
                    <h5>Select Website</h5>
                 </div>
                 <div class="flex-grow-1" v-if="loading">
                    <LoadingSpinner :loading="loading" />
                 </div>
                 <div class="flex-grow-1" v-else>
                    <div v-if="websites.length>0">
                       <select class="form-control" v-model="selectedWebsite" @change="onSelectWebsite(selectedWebsite)">
                          <option v-for="website in websites" :key="website.public_id" :value="website.public_id">
                             {{ website.website }}
                          </option>
                       </select>
                    </div>
                    <div v-else>
                     <select class="form-control">
                          <option >Select Website
                          </option>
                       </select>
                    </div>
                 </div>
              </div>
           </b-card-body>
        </iq-card>
     </b-col>
     <!-- Row for content -->
     <b-row class="content-body">
        <!-- Column for selecting website -->
        <!-- Column for displaying selected location -->
        <b-col lg="7">
           <iq-card class="iq-card-block iq-card-stretch iq-card-height">
              <div class="iq-card-header d-flex justify-content-between border-none">
                 <div class="iq-header-title">
                    <h5>Select Location</h5>
                 </div>
                 <!-- Server status indicators -->
                 <div class="d-flex align-items-center justify-content-between">
                    <a href="#" class="d-flex align-items-center mr-2">
                       <span class="bg-primary p-1 rounded mr-2"></span>
                       <p class="text-primary mb-0">Selected server</p>
                    </a>
                    <a href="#" class="d-flex align-items-center">
                       <span class="bg-success p-1 rounded mr-2"></span>
                       <p class="text-success mb-0" style="color: #28a745 !important">
                          Available server
                       </p>
                    </a>
                 </div>
              </div>
              <!-- Card body for displaying AmChart -->
              <div v-if="loading">
                 <LoadingSpinner :loading="loading" />
              </div>
              <div v-else>
                 <div v-if="websites.length>0">
                    <b-card-body>
                       <AmChart :key="selectedWebsite" element="world-map" type="dashboard-map" :height="250"
                       class="position-relative" :selectedCountryName="selectedCountryName"
                       :selectedCountryPublicID="selectedCountryPublicID" :selectedLatitude="selectedLatitude"
                       :selectedLongitude="selectedLongitude" v-if="
                       firstCountriesPublicId !== null &&
                       firstCountriesPublicId !== undefined
                       " :firstCountriesPublicId="firstCountriesPublicId.toString()" :websitePublicID="selectedWebsite"
                       @overallData="handleOverAllUpTime" @pageLoadTime="handlePageLoadTime"
                       @serverResponseTime="handleServerResponseTime" @overAlllatency="handleoverAlllatency" />
                    </b-card-body>
                 </div>
                 <div v-else>
                    <h5 class="text-center mb-2">{{ response_message }}</h5>
                 </div>
              </div>
           </iq-card>
        </b-col>
        <b-col lg="5">
           <iq-card class="iq-card-block iq-card-stretch iq-card-height">
              <b-card-body class="ethernet-content">
                 <div class="d-flex align-items-center">
                    <h5 class="mr-5" v-if="overAllUpTime !== null">{{ overAllUpTime }}%</h5>
                    <h2 v-else></h2>
                    <div class="float-right">
                       <h5>Overall Uptime:- Last 24 Hours</h5>
                       <!-- <p>+2.64%</p> -->
                    </div>
                 </div>
                 <div v-if="loading">
                    <LoadingSpinner :loading="loading" />
                 </div>
                 <div v-else>
                    <template v-if="loadTimeChart1.series[0].data && loadTimeChart1.series[0].data.length > 0">
                       <b-card-body style="
                          overflow-x: auto;
                          scrollbar-color: #00008b #fff;
                          scrollbar-width: thin;
                          ">
                          <div style="width: 1000px">
                             <ApexChart :key="loadTimeChartKey1" element="loadTimeChart1" :chartOption="loadTimeChart1" />
                          </div>
                       </b-card-body>
                    </template>
                    <template v-else>
                       <h5 class="text-center mt-5">{{ response_message }}</h5>
                    </template>
                 </div>
                 <!-- <div class="ethernet-text text-danger font-weight-600">+18%</div> -->
              </b-card-body>
           </iq-card>
        </b-col>
        <b-col lg="12" class="row m-0 p-0 iq-duration-block">
           <div class="col-sm-6 col-md-4 col-lg-3">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div class="icon iq-icon-box iq-bg-primary rounded">
                       <i class="ri-drag-move-2-fill"></i> 
                    </div>
                    <div class="mt-4 text-center">
                       <div v-if="loading">
                          <LoadingSpinner :loading="loading" />
                       </div>
                       <div v-else>
                          <h5
                             v-if="serverResponseTime !== null && serverResponseTime !== undefined && Object.keys(serverResponseTime).length > 0 && typeof pageLoadTime === 'number'">
                             {{ serverResponseTime }}s
                          </h5>
                          <h5 v-else>N/A</h5>
                       </div>
                       <h5>Server Response Time</h5>
                    </div>
                    <!-- <ApexChart
                       element="ethernet-chart-01"
                       :chartOption="ethernetChart1"
                       /> -->
                 </b-card-body>
              </iq-card>
           </div>
           <div class="col-sm-6 col-md-4 col-lg-3">
              <div class="iq-card iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <div class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div class="icon iq-icon-box iq-bg-success rounded" data-wow-delay="0.2s">
                       <i class="ri-artboard-2-line"></i>
                    </div>
                    <div class="mt-4 text-center">
                       <div v-if="loading">
                          <LoadingSpinner :loading="loading" />
                       </div>
                       <div v-else>
                          <h5 v-if="pageLoadTime !== null && pageLoadTime !== undefined && typeof pageLoadTime === 'number'">
                             {{ pageLoadTime }}s
                          </h5>
                          <h5 v-else>N/A</h5>
                       </div>
                       <h5>Page Loading Time</h5>
                    </div>
                    <!-- <ApexChart
                       element="ethernet-chart-02"
                       :chartOption="ethernetChart2"
                       /> -->
                 </div>
              </div>
           </div>
           <div class="col-sm-6 col-md-2 col-lg-3">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div v-if="loading">
                       <LoadingSpinner :loading="loading" />
                    </div>
                    <div v-else>
                       <div v-if="websites.length>0">
                          <div :class="{
                             'icon avatar-70 m-auto bg-close iq-border-close rounded-circle ':
                             this.contentMonitoring,
                             'icon avatar-70 m-auto bg-success iq-border-success rounded-circle':
                             !this.contentMonitoring,
                             }">
                             <i class="ri-close-line" v-if="this.contentMonitoring" style="color: white"></i>
                             <i class="ri-check-line" v-else style="color: white"></i>
                          </div>
                       </div>
                       <div v-else>
                          <h5 class="text-center mb-2">{{ response_message }}</h5>
                       </div>
                    </div>
                    <h5 class="text-center mt-3">
                       Content Monitoring
                    </h5>
                 </b-card-body>
              </iq-card>
           </div>
           <div class="col-sm-6 col-md-2 col-lg-3">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div v-if="loading">
                       <LoadingSpinner :loading="loading" />
                    </div>
                    <div v-else>
                       <div v-if="websites.length>0">
                          <div :class="{
                             'icon avatar-70 m-auto bg-close iq-border-close rounded-circle line-height-7':
                             this.serverreputation,
                             'icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7':
                             !this.serverreputation,
                             }">
                             <i class="ri-check-line" v-if="!this.serverreputation" style="color: white"></i>
                             <i class="ri-close-line" v-else style="color: white"></i>
                          </div>
                       </div>
                       <div v-else>
                          <h5 class="text-center mb-2">{{ response_message }}</h5>
                       </div>
                    </div>
                    <h5 class="text-center mt-3">
                       Server Reputation
                    </h5>
                 </b-card-body>
              </iq-card>
           </div>
           <div class="col-sm-6 col-md-4 col-lg-4">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div v-if="loading">
                       <LoadingSpinner :loading="loading" />
                    </div>
                    <div v-else>
                       <div v-if="websites.length>0">
                          <div :class="{
                             'icon avatar-70 m-auto bg-close iq-border-close rounded-circle line-height-7':
                             !this.fetch_dnssec,
                             'icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7':
                             this.fetch_dnssec,
                             }">
                             <i class="ri-check-line" v-if="this.fetch_dnssec" style="color: white"></i>
                             <i class="ri-close-line" v-else style="color: white"></i>
                          </div>
                       </div>
                       <div v-else>
                          <h5 class="text-center mb-2">{{ response_message }}</h5>
                       </div>
                    </div>
                    <h5 class="text-center mt-3">DNSSEC</h5>
                 </b-card-body>
              </iq-card>
           </div>
           <div class="col-sm-6 col-md-4 col-lg-4">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div v-if="loading">
                       <LoadingSpinner :loading="loading" />
                    </div>
                    <div v-else>
                       <div v-if="websites.length>0">
                          <div :class="{
                             'icon avatar-70 m-auto bg-close iq-border-close rounded-circle line-height-7':
                             !this.fetch_spf,
                             'icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7':
                             this.fetch_spf,
                             }">
                             <i class="ri-check-line" v-if="this.fetch_spf" style="color: white"></i>
                             <i class="ri-close-line" v-else style="color: white"></i>
                          </div>
                       </div>
                       <div v-else>
                          <h5 class="text-center mb-2">{{ response_message }}</h5>
                       </div>
                    </div>
                    <h5 class="text-center mt-3">SPF</h5>
                 </b-card-body>
              </iq-card>
           </div>
           <div class="col-sm-6 col-md-4 col-lg-4">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height d-flex align-items-center justify-content-center">
                 <b-card-body class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div v-if="loading">
                       <LoadingSpinner :loading="loading" />
                    </div>
                    <div v-else>
                       <div v-if="websites.length>0">
                          <div :class="{
                             'icon avatar-70 m-auto bg-close iq-border-close rounded-circle line-height-7':
                             !this.fetch_dmarc,
                             'icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7':
                             this.fetch_dmarc,
                             }">
                             <i class="ri-check-line" v-if="this.fetch_dmarc" style="color: white"></i>
                             <i class="ri-close-line" v-else style="color: white"></i>
                          </div>
                       </div>
                       <div v-else>
                          <h5 class="text-center mb-2">{{ response_message }}</h5>
                       </div>
                    </div>
                    <h5 class="text-center mt-3">DMARC</h5>
                 </b-card-body>
              </iq-card>
           </div>
           <b-col lg="8">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="d-flex flex-column align-items-center justify-content-center w-100">
                    <div class="iq-header-title">
                       <h5 class="card-title">Latency - Last 24 hours</h5>
                    </div>
                    <div class="mt-1"
                       v-if="overAlllatency !== null && overAlllatency !== undefined && Object.keys(overAlllatency).length > 0">
                       <div class="d-flex align-items-center justify-content-between">
                          <a href="#" class="d-flex align-items-center mr-4">
                             <span class="bg-primary p-1 rounded mr-2"></span>
                             <p class="text-primary mb-0">{{ overAlllatency }}</p>
                          </a>
                       </div>
                    </div>
                 </div>
                 <div v-if="loading">
                    <LoadingSpinner :loading="loading" />
                 </div>
                 <div>
                    <template v-if="loadTimeChart.series[0].data && loadTimeChart.series[0].data.length > 0">
                       <b-card-body style="
                          overflow-x: auto;
                          scrollbar-color: #00008b #fff;
                          scrollbar-width: thin;
                          ">
                          <div style="width: 1000px">
                             <ApexChart :key="loadTimeChartKey" element="loadTimeChart" :chartOption="loadTimeChart" />
                          </div>
                       </b-card-body>
                    </template>
                    <template v-else>
                       <h5 class="text-center mt-5">{{ response_message }}</h5>
                    </template>
                 </div>
              </iq-card>
           </b-col>
           <b-col lg="4" md="12">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                       <h5 class="card-title">Server Vulnerability</h5>
                    </div>
                 </div>
                 <div v-if="loading">
                    <LoadingSpinner :loading="loading" />
                 </div>
                 <div v-else>
                    <b-card-body class="iq-card-body">
                       <ApexChart v-if="!this.httpErrorChart.series.every(value => value === null)" element="httperror-chart"
                          :chartOption="httpErrorChart" :key="chartKey" style="min-height: 168.976px" />
                       <h5 v-else class="text-center mb-5">{{ response_message }}</h5>
                       <div class="row mt-3 error-detail ">
                          <template v-if="port_nw.length > 0">
                             <div class="col-2" v-for="port in port_nw" :key="port.public_id">
                                <div class="title position-relative pl-2">
                                   <span class="bg-danger rounded"></span>
                                   <h6>{{ port.service_name }}</h6>
                                   <p class="mb-0">{{ port.port }}</p>
                                </div>
                             </div>
                          </template>
                          <template v-else>
                             <h5 class="text-center mt-5">{{ response_message }}</h5>
                          </template>
                       </div>
                    </b-card-body>
                 </div>
              </iq-card>
           </b-col>
           <b-col lg="6">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                       <h5 class="card-title">Renewables SSL</h5>
                    </div>
                 </div>
                 <b-card-body>
                    <div class="table-responsive">
                       <div v-if="loading">
                          <LoadingSpinner :loading="loading" />
                       </div>
                       <div v-else>
                          <template v-if="ssl_start_date !== 'N/A' || ssl_expiry_date !== 'N/A'">
                             <div class="progress-container">
                                <div class="progress">
                                   <!-- Calculate the width dynamically based on progressPercentage -->
                                   <div class="progress-bar bg-danger" :style="{ width: progressPercentage.toFixed(2) + '%' }">
                                      {{ progressPercentage.toFixed(2) }}%
                                   </div>
                                   <div class="progress-bar bg-info"
                                      :style="{ width: (100 - progressPercentage).toFixed(2) + '%' }">
                                      {{ (100 - progressPercentage).toFixed(2) }}%
                                   </div>
                                </div>
                             </div>
                             <table class="table mb-0 table-borderless">
                                <thead>
                                   <tr>
                                      <th scope="col" style="text-align: left; width: 50%">Start Date</th>
                                      <th scope="col" style="text-align: right; width: 50%">End Date</th>
                                   </tr>
                                </thead>
                                <tbody>
                                   <tr>
                                      <td style="text-align: left; width: 50%">{{ ssl_start_date }}</td>
                                      <td style="text-align: right; width: 50%">{{ ssl_expiry_date }}</td>
                                   </tr>
                                </tbody>
                             </table>
                          </template>
                          <template v-else>
                             <h5 class="text-center">{{ response_message }}</h5>
                          </template>
                       </div>
                    </div>
                 </b-card-body>
              </iq-card>
           </b-col>
           <b-col lg="6">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                       <h5 class="card-title">Renewables Domain</h5>
                    </div>
                 </div>
                 <b-card-body>
                    <div class="table-responsive">
                       <div v-if="loading">
                          <LoadingSpinner :loading="loading" />
                       </div>
                       <div v-else>
                          <template v-if="domain_creation_date !== 'N/A' || domain_expiry_date !== 'N/A'">
                             <div class="progress-container">
                                <div class="progress">
                                   <div class="progress-bar bg-danger"
                                      :style="{ width: domainprogressPercentage.toFixed(2) + '%' }">
                                      {{ domainprogressPercentage.toFixed(2) }}%
                                   </div>
                                   <div class="progress-bar bg-info"
                                      :style="{ width: (100 - domainprogressPercentage).toFixed(2) + '%' }">
                                      {{ (100 - domainprogressPercentage).toFixed(2) }}%
                                   </div>
                                </div>
                             </div>
                             <table class="table mb-0 table-borderless">
                                <thead>
                                   <tr>
                                      <th scope="col" style="text-align: left; width: 50%">
                                         Start Date
                                      </th>
                                      <th scope="col" style="text-align: right; width: 50%">
                                         End Date
                                      </th>
                                   </tr>
                                </thead>
                                <tbody>
                                   <tr>
                                      <td style="text-align: left; width: 50%">
                                         {{ domain_creation_date }}
                                      </td>
                                      <td style="text-align: right; width: 50%">
                                         {{ domain_expiry_date }}
                                      </td>
                                   </tr>
                                </tbody>
                             </table>
                          </template>
                          <template v-else>
                             <h5 class="text-center">{{ response_message }}</h5>
                          </template>
                       </div>
                    </div>
                 </b-card-body>
              </iq-card>
           </b-col>
           <b-col lg="6">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                       <h5 class="card-title text-center">{{this.count}} Imitating Domain</h5>
                    </div>
                 </div>
                 <div v-if="loading">
                    <LoadingSpinner :loading="loading" />
                 </div>
                 <div v-else>
                    <b-card-body v-if="dns_domain && dns_domain.length > 0">
                       <div class="table-responsive">
                          <table class="table mb-0 table-borderless">
                             <thead>
                                <tr>
                                   <th scope="col">Domain</th>
                                </tr>
                             </thead>
                             <tbody>
                                <tr v-for="(item, index) in paginatedData" :key="index">
                                   <td>{{ item.permutation_domain }}</td>
                                </tr>
                             </tbody>
                          </table>
                       </div>
                       <template>
                          <div class="overflow-auto" v-if="dns_domain.length > 0">
                             <b-pagination v-model="currentPage" :total-rows="dns_domain.length" :per-page="itemsPerPage"
                                class="mt-4 float-left">
                                <template #first-text><span>First</span></template>
                                <template #prev-text><span>Prev</span></template>
                                <template #next-text><span>Next</span></template>
                                <template #last-text><span>Last</span> </template>
                                <template #page="{ page, active }">
                                   <b v-if="active">{{ page }}</b>
                                   <i v-else>{{ page }}</i>
                                </template>
                             </b-pagination>
                          </div>
                          <div class="overflow-auto" v-else>
                          </div>
                       </template>
                    </b-card-body>
                    <b-card-body v-else>
                       <h5 class="text-center mt-5">{{ response_message }}</h5>
                    </b-card-body>
                 </div>
              </iq-card>
           </b-col>
           <b-col lg="6" style="height: 550px; overflow-y: auto">
              <iq-card class="iq-card-block iq-card-stretch iq-card-height">
                 <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                       <h5 class="card-title">Dns Records</h5>
                    </div>
                 </div>
                 <b-card-body>
                    <div class="table-responsive">
                       <div v-if="loading">
                          <LoadingSpinner :loading="loading" />
                       </div>
                       <div v-else>
                          <template>
                             <table class="table">
                                <!-- <thead>
                                   <tr>
                                     <th scope="col">Type</th>
                                     <th scope="col">Value</th>
                                   </tr>
                                   </thead> -->
                                <tbody>
                                   <template v-if="dns_result && dns_result.length > 0">
                                      <tr v-for="(record, index) in dns_result" :key="index">
                                         <template v-if="record.records">
                                            <template v-for="(values, key) in record.records">
                                               <template v-if="values.length > 0">
                                      <tr v-for="(value, typeIndex) in values" :key="typeIndex">
                                      <td>{{ key }}</td>
                                      <td>{{ value.address || value || "-" }}</td>
                                      </tr>
                                      </template>
                                      </template>
                                      </template>
                                      </tr>
                                   </template>
                                   <template v-else>
                                      <h5 class="text-center mt-5">{{ response_message }}</h5>
                                   </template>
                                </tbody>
                             </table>
                          </template>
                       </div>
                    </div>
                 </b-card-body>
              </iq-card>
           </b-col>
        </b-col>
     </b-row>
  </b-container>
</template>

<script>
import { core } from "../../config/pluginInit";
import ApexChart from "../../components/core/charts/ApexChart";
import AmChart from "../../components/core/charts/AmChart";
import axios from "axios";
import constant, { decryptToken, response_message } from "@/config/constant";
import LoadingSpinner from "@/config/Pageloader.vue"; 
import { BPagination } from "bootstrap-vue";

export default {
  name: "Dashboard2",
  components: { ApexChart, AmChart, BPagination,  LoadingSpinner, },
  data() {
    return {
      // startDate: new Date('2024-01-01'), // Replace with your start date
      // endDate: new Date('2024-12-31'), // Replace with your end date
      currentDate: new Date(),
      loading: true,
      // Data variables
      currentPage: 1,
      itemsPerPage: 5,
      count:0,
      websites: [],
      port_nw: [],
      dns_domain: [],
      dns_result: [],
      selectedWebsite: null,
      firstCountriesPublicId: null,
      response_message: response_message,
      ssl_start_date: null,
      ssl_expiry_date: null,
      domain_creation_date: null,
      domain_expiry_date: null,
      selectedCountryName: [],
      selectedCountryPublicID: [],
      selectedLatitude: [],
      selectedLongitude: [],
      overAllUpTime: null,
      ethernetChartKey: 0,
      loadTimeChartKey: 0,
      loadTimeChartKey1: 0,
      pageLoadTime: null,
      serverResponseTime: null,
      contentMonitoring: null,
      fetch_spf: null,
      fetch_dmarc: null,
      fetch_dnssec: null,
      serverreputation: null,
      overAlllatency: null,
      ethernetChart: {
        series: [
          {
            name: "OverAllUpTime",
            data: [],
          },
        ],
        chart: {
          height: '200',
          width: '100%',
          type: "line",
          zoom: {
            enabled: false,
          },
          sparkline: {
            enabled: true,
          },
        },
        colors: ["#ff7750"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: 'X-Axis Label',
            style: {
              fontSize: '14px',
            },
          },
        },
        yaxis: {
          title: {
            text: 'Y-Axis Label',
            style: {
              fontSize: '14px',
            },
          },
        },
      },
      loadTimeChart: {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        chart: {
          height: 290,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              zoom: true, // Enable zoom tool
              zoomin: true, // Enable zoom in tool
              zoomout: true, // Enable zoom out tool
              pan: false, // Disable pan tool
              reset: true | '<img src="/static/icons/reset.png" width="20">', // Enable reset tool, you can customize the reset icon if you want
            },
            offsetX: '50%', // Center the toolbar horizontally
            offsetY: 0,
          },

        },
        colors: ["#140958", "#ff7750"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          tickAmount: 13,
          categories: [], // Initially empty, will be populated dynamically
          title: {
            text: "",
          },
          labels: {
            rotate: -45, // Rotate x-axis labels for better readability

          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            offsetX: -10,
            offsetY: 0,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },

      },
      loadTimeChart1: {
        series: [
          {
            name: "",
            data: [],
          },
        ],
        chart: {
          height: 290,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: true,
            tools: {
              zoom: true, // Enable zoom tool
              zoomin: true, // Enable zoom in tool
              zoomout: true, // Enable zoom out tool
              pan: false, // Disable pan tool
              reset: true | '<img src="/static/icons/reset.png" width="20">', // Enable reset tool, you can customize the reset icon if you want
            },
            offsetX: '50%', // Center the toolbar horizontally
            offsetY: 0,
          },

        },
        colors: ["#140958", "#ff7750"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          padding: {
            left: 0,
            right: 0,
            bottom: 0,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          tickAmount: 13,
          categories: [], // Initially empty, will be populated dynamically
          title: {
            text: "",
          },
          labels: {
            rotate: -45, // Rotate x-axis labels for better readability

          },
        },
        yaxis: {
          title: {
            text: "",
          },
          labels: {
            offsetX: -10,
            offsetY: 0,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },

      },

      httpErrorChart: {
        series: [],
        colors: ["#af0404", "#e84747", "#e3a857", "#140958"],
        labels: ["Critical", "High", "Medium", "Low"],
        chart: {
          type: "donut",
          height: 200,
          sparkline: {
            enabled: false,
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {},
                legend: {
                  show: false,
                },
              },
            },
          ],


        },
      },
      chartKey: 0,
    };
  },
  mounted() {
    setInterval(() => {
      this.currentDate = new Date();
    }, 1000);
    // Initialize core functionality
    core.index();
    // Fetch website names
    this.fetchWebsiteNames();
    // Select the first website if none is selected yet
    if (this.websites.length > 0 && !this.selectedWebsite) {
      this.selectedWebsite = this.websites[0].public_id;
    }
  },
  watch: {
    // Watch for changes in selectedWebsite
    selectedWebsite(newValue, oldValue) {
      if (!oldValue && newValue && this.websites.length > 0) {
        // If no old value, new value is set, and websites array is not empty
        // Call onSelectWebsite function
        this.onSelectWebsite(newValue);
        // Get the first website's country ID
        const firstWebsite = this.websites.find(
          (website) => website.public_id === newValue
        );
        if (firstWebsite) {
          this.firstCountriesPublicId = firstWebsite.countries_public_id[0];
        }
      }
    },
  },
  computed: {
    domainprogressPercentage() {
      const startDate = new Date(this.domain_creation_date);
      const expiryDate = new Date(this.domain_expiry_date);
      const currentDate = new Date();

      const totalDuration = expiryDate - startDate;
      const elapsedDuration = currentDate - startDate;

      return (elapsedDuration / totalDuration) * 100;
    },
    progressPercentage() {
      const startDate = new Date(this.ssl_start_date);
      const expiryDate = new Date(this.ssl_expiry_date);
      const currentDate = new Date();

      const totalDuration = expiryDate - startDate;
      const elapsedDuration = currentDate - startDate;

      return (elapsedDuration / totalDuration) * 100;

    },
    progressWidth() {
      var startDate = new Date(this.domain_creation_date);
      var endDate = new Date(this.domain_expiry_date);
      const totalDays = endDate.getTime() - startDate.getTime();
      const elapsedDays = this.currentDate.getTime() - startDate.getTime();
      const progressPercentage = (elapsedDays / totalDays) * 100;
      return `${progressPercentage}%`;
    },
    progressBarColor() {
      const oneDay = 30 * 24 * 60 * 60 * 1000; // milliseconds in a day
      var startDate = new Date(this.domain_creation_date);
      var endDate = new Date(this.domain_expiry_date);
      const timeDifference = endDate.getTime() - this.currentDate.getTime();
      if (timeDifference <= oneDay) {
        return 'red';
      }
      return '#007bff'; // Default color
    },
    pageCount() {
      return Math.ceil(this.dns_domain.length / this.itemsPerPage);
    },
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.dns_domain.slice(startIndex, endIndex);
    },
  },
  methods: {
    // Method to fetch website names from API
    async fetchWebsiteNames() {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL + "web-monitoring/web-monitors/",
          { headers: { Authorization: "Token " + decryptedToken } }
        );
        this.websites = response.data.data;
        this.loading = false;
        // Set the first countries's public_id
        if (this.websites.length > 0 && !this.selectedWebsite) {
          this.selectedWebsite = this.websites[0].public_id;
          this.firstCountriesPublicId = this.websites[0].countries_public_id[0];
          this.ssl_start_date = this.websites[0].ssl_issue_at;
          this.ssl_expiry_date = this.websites[0].ssl_expiry_at;
          this.domain_creation_date = this.websites[0].domain_creation_at;
          this.domain_expiry_date = this.websites[0].domain_expiry_at;
        }
        else {
          this.ssl_start_date = 'N/A';
          this.ssl_expiry_date = 'N/A';
          this.domain_creation_date = 'N/A';
          this.domain_expiry_date = 'N/A';
        }
      } catch (error) {
        this.loading = false;

      }
    },
    async fetchVulnerability(public_id) {

      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "vms/website-vm/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        const critical = response.data.data.severity_levels.critical;
        const high = response.data.data.severity_levels.high;
        const medium = response.data.data.severity_levels.medium;
        const low = response.data.data.severity_levels.low;
        this.httpErrorChart.series = [critical, high, medium, low];

        // const port=response.data.data.ports;
        this.port_nw = response.data.data.ports;
        this.chartKey++;

      } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },
    // Method to handle selection of website
    async onSelectWebsite(public_id) {
      // Reset data arrays
      this.selectedCountryName = [];
      this.selectedCountryPublicID = [];
      this.selectedLatitude = [];
      this.selectedLongitude = [];
      this.dns_domain = [];
      this.contentMonitoring = null;
      this.fetch_spf = null;
      this.fetch_dmarc = null;
      this.fetch_dnssec = null;
      this.dns_result = [];
      this.fetchVulnerability(public_id);
      this.fetchContentMonitoring(public_id);
      this.fetchSpfRecord(public_id),
        this.fetchServerReputation(public_id);
      this.fetchDomainNames(public_id);
      this.fetchDnsRecordList(public_id);
      try {
        // Fetch countries associated with the selected website
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/web-monitors/website_countries/?public_id=" +
          public_id,
          { headers: { Authorization: "Token " + decryptedToken } }
        );
        const result = response.data.data;
        // Extract data from the response
        this.selectedCountryName = result.map((country) => country.city);
        this.selectedCountryPublicID = result.map(
          (country) => country.public_id
        );
        this.selectedLatitude = result.map((country) => country.latitude);
        this.selectedLongitude = result.map((country) => country.longitude);
        const selectedWebsite = this.websites.find(
          (website) => website.public_id === public_id
        );
        if (selectedWebsite) {
          const sslStartDate = selectedWebsite.ssl_issue_at
            ? new Date(selectedWebsite.ssl_issue_at)
            : null;
          const sslEndtDate = selectedWebsite.ssl_expiry_at
            ? new Date(selectedWebsite.ssl_expiry_at)
            : null;
          const domainStartDate = selectedWebsite.domain_creation_at
            ? new Date(selectedWebsite.domain_creation_at)
            : null;
          const domainEndtDate = selectedWebsite.domain_expiry_at
            ? new Date(selectedWebsite.domain_expiry_at)
            : null;
          if (sslStartDate) {
            const options = { day: "numeric", month: "short", year: "numeric" };
            this.ssl_start_date = sslStartDate.toLocaleDateString(
              "en-US",
              options
            );
          } else {
            this.ssl_start_date = "N/A";
          }
          if (sslEndtDate) {
            const options = { day: "numeric", month: "short", year: "numeric" };
            this.ssl_expiry_date = sslEndtDate.toLocaleDateString(
              "en-US",
              options
            );
          } else {
            this.ssl_expiry_date = "N/A";
          }

          if (domainStartDate) {
            const options = { day: "numeric", month: "short", year: "numeric" };
            this.domain_creation_date = domainStartDate.toLocaleDateString(
              "en-US",
              options
            );
          } else {
            this.domain_creation_date = "N/A";
          }

          if (domainEndtDate) {
            const options = { day: "numeric", month: "short", year: "numeric" };
            this.domain_expiry_date = domainEndtDate.toLocaleDateString(
              "en-US",
              options
            );
          } else {
            this.domain_expiry_date = "N/A";
          }
        }
        //     this.handleOverAllUpTime({
        //   overAllUpTime: this.overAllUpTime,
        //   // You can pass any additional data here if needed
        // });
        // Fetch additional data based on the selected website
      } catch (error) {
      }
      finally {
        this.loading = false;
      }
    },

    handleOverAllUpTime(data) {
      this.overAllUpTime = data.overAllUpTime;
      const uptimes = data.uptimes ? data.uptimes : [];
      const chart_uptime = uptimes.map((value) => value);

      this.loadTimeChart1.series[0].data = chart_uptime;
      // this.ethernetChart.series[0].data = chart_uptime;
      const chart_dates = data.dates ? data.dates : [];
      const chart_date = chart_dates.map((value) => value);

      // this.ethernetChart.xaxis.categories = chart_date;
      this.loadTimeChart1.xaxis.categories = chart_date
      this.loadTimeChartKey1++;
    },
    handleoverAlllatency(data) {
      try {
        if (data.overAlllatency && data.overAlllatency.data && data.overAlllatency.data.length > 0) {
          this.overAlllatency = data.overAlllatency.name;
          const latency_uptime = data.overAlllatency.data;
          const formattedData = latency_uptime.map((value) => value);

          this.loadTimeChart.series[0].data = formattedData;
          const label_city_name = data.overAlllatency.name;
          this.loadTimeChart.series[0].name = label_city_name;

          const latency_times = data.overAlllatency.latency_time;
          this.loadTimeChart.xaxis.categories = latency_times.map((time) =>
            String(time)
          );
          this.loadTimeChartKey++;
        } else {
          // Clear the graph data if no new data is available
          this.overAlllatency = data.overAlllatency.name;
          this.loadTimeChart.series[0].data = [];
          this.loadTimeChart.xaxis.categories = [];
        }
      } catch (error) {
      }
    },


    handlePageLoadTime(data) {
      this.pageLoadTime = data;
    },

    handleServerResponseTime(data) {
      if (typeof data === "string") {
        // Round to two decimal places and convert to string
        this.serverResponseTime = parseFloat(data).toFixed(2);
      } else {
        this.serverResponseTime = "N/A";
      }
    },

    

    async fetchContentMonitoring(public_id) {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/content-monitor/get_web_content/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        this.contentMonitoring = response.data.data.detected;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    async fetchSpfRecord(public_id) {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/email-profiles/get_email_profile/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        this.fetch_spf = response.data.data.spf_record;
        this.fetch_dmarc = response.data.data.dmarc_record;
        this.fetch_dnssec = response.data.data.dnssec_record;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    async fetchServerReputation(public_id) {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/server-reputation/get_web_reputation/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        this.serverreputation = response.data.data.detected;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },


    async fetchDomainNames(public_id) {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/dns-input/get_imitating_domain/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );

        this.dns_domain = response.data.data;
        this.count=response.data.count
      
      } catch (error) {
        this.count=0
      }finally {
        this.loading = false;
      }
    },

    async fetchDnsRecordList(public_id) {
      try {
        const token = localStorage.getItem("token");
        const key = "enc";
        const decryptedToken = decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL +
          "web-monitoring/dns-records/get_domain_record/?website_public_id=" +
          public_id,
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        this.dns_result = response.data.data;
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

[dir="ltr"][mode="light"] .ethernet-content {
  z-index: unset;
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Additional styles for row and columns to ensure centering */
.centered-content .row,
.centered-content .col-lg-12 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Override styles for the avatar icon container */
.icon.avatar-70 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  /* Assuming avatar-70 is meant to set the width to 70px */
  height: 70px;
  /* Set the height to match the width for a circle */
  margin: 0 auto;
  /* Centers the icon horizontally */
}

/* Optional: ensure the text is centered within the column */
.text-center {
  text-align: center;
  width: 100%;
  /* Full width for proper centering */
}
</style>
